$(() => {
  const controlSendEmailCheckbox = (checkbox) => {
    const alertTypeId = $(checkbox).prop('id');
    const sendEmailId = '#' + alertTypeId + '_send_email';
    const $sendEmailCheckbox = $(sendEmailId);
    if ($sendEmailCheckbox.hasClass('js-disabledCheckbox')) {
      return;
    }

    if ($(checkbox).prop('checked')) {
      $sendEmailCheckbox.prop('disabled', false);
      $sendEmailCheckbox.next('label').removeClass('disabled');
    } else {
      $sendEmailCheckbox.prop('disabled', true).prop('checked', false);
      $sendEmailCheckbox.next('label').addClass('disabled');
    }
  };

  const controlSelectBox = (checkbox) => {
    const alertTypeId = $(checkbox).prop('id');
    const selectBoxId = '#' + alertTypeId + '_select_box';
    const $selectBox = $(selectBoxId);
    if ($selectBox.length == 0) { return }
    if ($(checkbox).prop('checked')) {
      $selectBox.prop('disabled', false);
    } else {
      $selectBox.prop('disabled', true).prop('checked', false);
    }
  }

  $('.js-enabled-alert input[type="checkbox"]').on('change', (event) => {
    const checkbox = event.currentTarget;
    controlSendEmailCheckbox(checkbox);
    controlSelectBox(checkbox);
  });
  $('.js-enabled-alert input[type="checkbox"]').each((_index, checkbox) => {
    controlSendEmailCheckbox(checkbox);
    controlSelectBox(checkbox);
  });

  $('#alert-modal-cancel-button').on('click', (e) => {
    e.preventDefault();
    const modal: any = $('#js-notificationEmailModal');
    modal.modal('hide');
    return false;
  });
});
