$(function() {
  $('.c-Hamburger, .c-PageHeader-hamburger-menuTitle').on('click',function() {
    $(this).toggleClass('active');

    if ($(this).hasClass('active')) {
      $('.globalMenuSp').addClass('active');
    } else {
      $('.globalMenuSp').removeClass('active');
    }
  });

  $('.globalMenuSp-menuFooter').on('click',function() {
    $('.c-Hamburger').removeClass('active');
    $('.globalMenuSp').removeClass('active');
  });

  // 今後トグルをデフォルトで閉じるようにする際にコメントアウトを戻す。
  // $(".globalMenuSp-content-menuList:has(.currentPage)")
  //   .addClass("openList");
  //
  // $(".globalMenuSp-content:has(.openList)")
  //   .before()
  //   .slideToggle()
  //   .addClass("open");

  const $pageMain = $('.c-Page-main')

  if ($('.FlashMessageArea').length && $pageMain.length) {
    $pageMain.css('marginTop', 0)
  }
});

$(function () {
  $('.js-accordion-title').on('click', function () {
    $(this).next().slideToggle(200);
    $(this).toggleClass('open');
  });
});