const initScrollAnimation = () => {
  const fadeInTarget = document.querySelectorAll(".js-fade-in");
  
  const scrollAnimation = () => {
    for (let i = 0; i < fadeInTarget.length; i++) {
      const rect = fadeInTarget[i].getBoundingClientRect().top;
      const scroll = window.pageYOffset || document.documentElement.scrollTop;
      const offset = rect + scroll;
      const windowHeight = window.innerHeight;
      if (scroll > offset - windowHeight + 150) {
        fadeInTarget[i].classList.add("scroll-in");
      }
    }
  };
  
  window.addEventListener("scroll", scrollAnimation, false);
}

const initPressCarousel = () => {
  const pressCardContainer = document.querySelector(
    ".js-press-card-scroll-container",
  );
  const scrollRight = () => {
    pressCardContainer.scrollBy({
      left: 300,
      behavior: "smooth",
    });
  };
  const scrollLeft = () => {
    pressCardContainer.scrollBy({
      left: -300,
      behavior: "smooth",
    });
  };
  
  const rightButton = document.querySelector(".js-press__button--right");
  const leftButton = document.querySelector(".js-press__button--left");
  
  if (rightButton && leftButton) {
    rightButton.addEventListener("click", scrollRight);
    leftButton.addEventListener("click", scrollLeft);
  }
}

document.addEventListener("DOMContentLoaded", () => {
  initScrollAnimation();
  initPressCarousel();
});
