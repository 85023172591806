$(() => {
  // 管理者アクセス権限
  const adminAccessPattern = {
    view_bank: true,
    create_bank: true,
    authenticate_bank: true,
    delete_bank: true,
    only_own_transaction: false,
    view_transaction: true,
    create_transaction: true,
    edit_transaction: true,
    approve_transaction: true,
    delete_transaction: true,
    only_own_file: false,
    view_file: true,
    create_file: true,
    edit_file: true,
    share_file: true
  };
  // 一般アクセス権限
  const generalAccessPattern = {
    view_bank: true,
    create_bank: false,
    authenticate_bank: false,
    delete_bank: false,
    only_own_transaction: true,
    view_transaction: true,
    create_transaction: true,
    edit_transaction: true,
    approve_transaction: false,
    delete_transaction: true,
    only_own_file: true,
    view_file: true,
    create_file: true,
    edit_file: true,
    share_file: false
  };
  
  // 機能の利用権限の編集
  $('.js-btn-permission-settings-edit').on('click', (event) => {
    $('.js-btn-permission-settings-cancel').removeAttr("style");
    $('.js-btn-permission-settings-save').removeAttr("style");
    event.currentTarget.setAttribute("style", "display: none");
    // ラジオボタンの活性
    $('form[id^=edit_user_operation_authority]').find('input[type="radio"]').prop('disabled', false);
    if ($('input[name="user_operation_authority[control_authority]"]:checked').val() === "3") {
      $('form[id^=edit_user_operation_authority]').find('input[type="checkbox"]').prop('disabled', false);
    }
  })
  // 機能の利用権限のキャンセル
  $('.js-btn-permission-settings-cancel').on('click', (event) => {
    $('.js-btn-permission-settings-edit').removeAttr("style");
    $('.js-btn-permission-settings-save').css("display", "none");
    event.currentTarget.setAttribute("style", "display: none");
    // ラジオボタン・チェックボックスの非活性
    $('form[id^=edit_user_operation_authority]').find('input[type="radio"]').prop('disabled', true);
    $('form[id^=edit_user_operation_authority]').find('input[type="checkbox"]').prop('disabled', true);
  })

  // まとめて権限管理
  $('input[name="user_operation_authority[control_authority]"]').on('change', (event) => {
    setBulkPermission(parseInt(event.currentTarget['value']));
  })
  // 一括権限設定
  const setBulkPermission = (permissionType: number) => {
    switch(permissionType) {
      case 1: // 管理者同等
      case 2: // 一般利用
        const accessPattern = permissionType === 1 ? adminAccessPattern : generalAccessPattern;
        Object.keys(accessPattern).forEach((key) => {
          $(`#user_operation_authority_${key}`).prop('checked', accessPattern[key]).prop('disabled', true);
        });
        break;
      case 3: // 個別に設定
        $('form[id^=edit_user_operation_authority]').find('input[type="checkbox"]').prop('disabled', false);
        break;
      default:
        break;
    }
  }
});
