/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

svg4everybody();

// Bootstrap
import "bootstrap/dist/js/bootstrap.bundle";
import './invoices';
import './alertConditions';
import './financialSettings';
import './hamburgerMenu';
import './permissionSettings';
import './pressCarousel';

import "jquery-inview/jquery.inview.min";

$(function () {
  $('[data-toggle="tooltip"]').tooltip();
});

import Swal from 'sweetalert2'

$(() => {
  // Q&Aページの回答のトグル処理
  const $questionLink = $(".js-question-link");
  if ($questionLink.length) {
    $($questionLink).on("click", e => {
      $(e.currentTarget)
        .find(".js-answer-toggle")
        .slideToggle("fast");
      $(e.currentTarget)
        .find(".js-question-chevron")
        .toggleClass("is-open");
    });
  }

  // 必要書類の提出のファイル選択処理
  $('input[type=file].js-review-document').on('change', e => {
    const target = $(e.target);
    const file = target.prop('files')[0];
    if (file) {
      target.siblings('.js-filename').text(file.name);
      target.siblings('.js-review-document-link').hide();
    } else {
      target.siblings('.js-filename').text('');
      target.siblings('.js-review-document-link').show();
    }
  });

  $('input[type=file].js-review-document-submit').on('change', e => {
    const target = $(e.target);
    if (e.target.files[0].size > 0) {
      target.parents('form').submit();
    }
  });

  // 利用規約同意のトグル処理
  (() => {
    const $cb = $("#js-term-checkbox");
    const $btn = $("#js-term-button");
    if (!($cb.length && $btn.length)) {
      return;
    }

    $cb.prop("checked", false);
    $btn.prop("disabled", true);

    const changeButtonState = () => {
      if ($cb.prop("checked")) {
        $btn.prop("disabled", false);
      } else {
        $btn.prop("disabled", true);
      }
    };

    $($cb).on("change", () => {
      changeButtonState();
    });
  })();

  // MTLINK連携ページの同意ボタンのトグル処理
  (() => {
    const $cb1 = $("#js-mtlink-checkbox1");
    const $cb2 = $("#js-mtlink-checkbox2");
    const $btn1 = $("#js-mtlink-agreement-button");
    const $btn2 = $("#js-mtlink-disabled-button");
    if (!($cb1.length && $cb2.length && $btn1.length && $btn2.length)) {
      return;
    }

    $cb1.prop("checked", false);
    $cb2.prop("checked", false);
    $btn1.hide();
    $btn2.show();

    const isAgreed = () => {
      return $cb1.prop("checked") && $cb2.prop("checked");
    };

    const changeButtonState = () => {
      if (isAgreed()) {
        $btn1.show();
        $btn2.hide();
      } else {
        $btn1.hide();
        $btn2.show();
      }
    };

    $($cb1.add($cb2)).on("change", () => {
      changeButtonState();
    });
  })();

  // 全てのチェックボックスを選択するボタンの処理
  $(".js-check-all").on("click", e => {
    e.preventDefault();
    const el = $(e.target).data("target");
    $(el).prop("checked",true);
  });

  // ダミーデータをセットする
  $('.js-set-dummy-data').on('click', e => {
    e.preventDefault();
    const dummyData = $(e.target).data('dummy');
    $('input,select').each((_, element) => {
      const name = $(element).attr('name')
      dummyData[name] && $(element).val(dummyData[name]);
    });
  });

  $('.btn-confirm-mt-link').on('click', (e) => {
    const data = $(e.target).parents().find('.btn-confirm-mt-link').data();
    Swal.fire({
      animation: false,
      title: '注意事項',
      type: 'warning',
      html: `
      <ul>
      <li>
          MT
          LINK側ですでに連携済みの口座を削除すると、当該口座の過去分のデータはすべて消去されてしまいます。また、エメラダ・キャッシュマネージャー側でも、当該過去分のデータがすべて削除され、表示されなくなります。
      </li>
      <li>
          さらに、当該口座を再接続しても、MT LINK側で当該口座の過去分のデータは消去されておりますので、MT
          LINK側とエメラダ・キャッシュマネージャー側双方において、当該口座の過去分のデータの復元はできません。
      </li>
      <li>連携済みの口座を削除する場合は、上記に留意の上、慎重にご判断ください。</li>
      </ul>
  `,
      showCancelButton: true,
      confirmButtonText: 'キャンセル',
      cancelButtonText: 'OK'
    }).then(({ value }) => {
      const redirect = data.redirectUrl;
      if (!value) {
        if (data.newWindow) {
          window.open(redirect, 'Moneytree', 'height=900, width=1200, scrollbars=yes');
        } else {
          window.location = redirect;
        }
      }
    })
  });
});
