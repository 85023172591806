$(() => {
  const accountTypeOptions = Array.from((() => {
    const selectTag = document.getElementById('js-deposit-balance-body');
    return selectTag ? selectTag.getElementsByTagName('option') : [];
  })());
  // ecm_common/app/frontend/src/styles/icons/Trash.tsx, 及び financial_settings_helper#trash_iconと同じタグを作成する
  const trashIcon = () => {
    const xmlns = 'http://www.w3.org/2000/svg';
    const trashLink = document.createElement('a');
    trashLink.setAttribute('href', '#');
    trashLink.classList.add('js-trash');

    const svg = document.createElementNS(xmlns, 'svg');
    svg.setAttributeNS(null, 'viewBox', '0 0 25 25');
    svg.setAttributeNS(null, 'width', '24');
    trashLink.appendChild(svg);

    const pathTag = document.createElementNS(xmlns, 'path');
    pathTag.setAttributeNS(null, 'fill', '#46909d');
    pathTag.setAttributeNS(null, 'className', 'ic-delete');
    pathTag.setAttributeNS(null, 'd', 'M8,21a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V9H8ZM21,6H17.5l-1-1h-5l-1,1H7V8H21Z');
    svg.appendChild(pathTag);

    return trashLink;
  };

  const addRow = (targetId, bodyId, depositTarget: boolean) => {
    const newObject = depositTarget ? createNewDepositRow(targetId) : createNewDebtRow(targetId);
    if (!validate(newObject)) {
      alert('未入力の項目があります。入力してください。');
      return false
    }

    const newRow = buildTr(newObject, depositTarget);

    const parentBody = document.getElementById(bodyId);
    parentBody.appendChild(newRow);

    resetInput(targetId);

    return false;
  };

  const deleteRow = (trashLinkElement) => {
    const deleteTr = trashLinkElement.parentNode.parentNode;
    deleteTr.parentNode.removeChild(deleteTr);
  };

  const buildTr = (object, depositTarget) => {
    const arrayName = depositTarget ? 'deposit_balances' : 'outstanding_borrowings'

    const tr = document.createElement('tr');
    for (var key of Object.keys(object)) {
      const td = document.createElement('td');
      const keyName = `financial_setting[${arrayName}][][${key}]`;
      if (key === 'account_type') {
        const select = document.createElement('select');
        select.classList.add('form-control');
        select.classList.add('c-Form-control');
        for (var accountType of accountTypeOptions) {
          select.appendChild(accountType.cloneNode(true));
        }
        select.name = keyName;
        select.value = object[key];
        td.appendChild(select);
      } else if (key === 'public_banking') {
        const label = document.createElement('label');
        label.classList.add('d-FinancialSettingsIndex-publicBanking-label');
        const checkbox = document.createElement('input');
        checkbox.classList.add('form-control');
        checkbox.classList.add('c-Form-control');
        checkbox.classList.add('d-FinancialSettingsIndex-checkBox');
        checkbox.type = 'checkbox';
        checkbox.name = keyName;
        checkbox.value = 'true';
        checkbox.checked = object[key];
        const text = document.createElement('span');
        text.textContent = '公庫の場合はこちらをチェックしてください';
        label.appendChild(checkbox);
        label.appendChild(text);
        td.appendChild(label);
      } else {
        const input = document.createElement('input');
        input.classList.add('form-control');
        input.classList.add('c-Form-control');
        input.name = keyName;
        input.value = object[key];
        td.appendChild(input);
      }
      tr.appendChild(td);
    }
    const trashTd = document.createElement('td');
    trashTd.appendChild(trashIcon());
    tr.appendChild(trashTd);

    const disabledRadioTd = document.createElement('td');
    const disabledRadio = document.createElement('input');
    disabledRadio.setAttribute('type', 'radio');
    disabledRadio.setAttribute('disabled', 'true');
    disabledRadioTd.appendChild(disabledRadio);
    tr.appendChild(disabledRadioTd);

    return tr;
  };

  const createNewDepositRow = (targetId) => {
    const inputRow = $(`#${targetId}`).parent('td').parent('tr');
    const rowObject = {
      bank_name: inputRow.find('.js-bank-name').val(),
      branch_name: inputRow.find('.js-branch-name').val(),
      account_type: inputRow.find('.js-account-type').val(),
      account_number: inputRow.find('.js-account-number').val(),
      amount: inputRow.find('.js-balance').val()
    };
    return rowObject;
  };

  const createNewDebtRow = (targetId) => {
    const inputRow = $(`#${targetId}`).parent('td').parent('tr');
    const rowObject = {
      bank_name: inputRow.find('.js-bank-name').val(),
      public_banking: inputRow.find('.js-public-banking').prop('checked'),
      amount: inputRow.find('.js-debt-balance').val()
    };
    return rowObject;
  };

  const validate = (rowObject) => {
    // 値が存在しない項目が存在したらNGとする
    // 利用者による削除が容易なため入力チェックは存在チェックのみとする
    const blankKey = Object.keys(rowObject).find(key => {
      return rowObject[key] == null;
    });
    return !blankKey;
  };

  const resetInput = (targetId) => {
    const inputRow = $(`#${targetId}`).parent('td').parent('tr');
    inputRow.find('.js-bank-name').val(null);
    inputRow.find('.js-branch-name').val(null);
    inputRow.find('.js-account-type').val('普通預金');
    inputRow.find('.js-account-number').val(null);
    inputRow.find('.js-balance').val(null);
    inputRow.find('.js-debt-balance').val(null);
    inputRow.find('.js-public-banking').prop('checked', false);
  };

  $('#js-add-new-deposit-balance').on('click', (event) => {
    event.preventDefault();
    addRow('js-add-new-deposit-balance', 'js-deposit-balance-body', true);
    return false;
  });

  $('#js-add-new-debt-balance').on('click', (event) => {
    event.preventDefault();
    addRow('js-add-new-debt-balance', 'js-debt-balance-body', false);
    return false;
  });

  $('.js-document-uploader').on('change', (event) => {
    event.preventDefault();

    const fileInput = event.target;
    const fileParent = fileInput.parentNode;
    const sizeIndex = (fileParent.querySelector('[name=js-document-index]') as HTMLInputElement).value;

    const documentInput = document.createElement('input');
    documentInput.type = 'hidden';
    documentInput.name = `review_documents[${sizeIndex}][document_type]`;
    documentInput.value = (fileParent.querySelector('[name=js-document-type]') as HTMLInputElement).value;
    const idInput = document.createElement('input');
    idInput.type = 'hidden';
    idInput.name = `review_documents[${sizeIndex}][id]`;
    const idValueInput = fileParent.querySelector('[name=js-document-id]') as HTMLInputElement;
    if (idValueInput) {
      idInput.value = idValueInput.value;
    }

    const form = document.getElementById('js-file-upload-form');
    form.appendChild(fileInput);
    form.appendChild(idInput);
    form.appendChild(documentInput);
    $(form).submit();

    return false;
  });

  $(document).on('click', '.js-trash', (event) => {
    event.preventDefault();
    deleteRow(event.currentTarget);
    return false;
  });

  const reloadBalanceChart = (mpCompanyId, yearMonth, balanceType, targetClass) => {
    const path = `/api/ecm/v1/end_of_month_balances`;
    const query = `year_month=${yearMonth}&balance_type=${balanceType}`;
    const url = `${path}?${query}`;
    const inputName = balanceType == 'deposit' ? 'balance' : 'debt_balance';

    $.ajax(url, {
      type: 'GET'
    }).done((response) => {
      updateRows(response, targetClass, inputName);
    });
  };

  const updateRows = (balances, targetClass, inputName) => {
    $(targetClass).each((index, element) => {
      const $rowTr = $(element);
      const $valueTd = $rowTr.find('.js-balance-value');
      const accountId = $rowTr.data('id');
      const hitBalance = balances.find(balance => { return balance.id == accountId });

      if (hitBalance) {
        $valueTd.empty();
        const valueText = '￥ ' + hitBalance.value.toLocaleString();
        const valueItem = $('<span>', { text: valueText});
        if (hitBalance.value < 0) {
          valueItem.css({color: 'red'});
        }
        $valueTd.append(valueItem);
      } else {
        if (!$valueTd.find('input').length) {
          $valueTd.empty();
          $('<input>', {
            // TODO: balance_withのbalance部分は可変
            name: `financial_setting[${inputName}_with_account][${accountId}]`,
            class: 'form-control c-Form-control'
          }).appendTo($valueTd);
        }
      }
    });
  };

  $('.js-reload-balance').on('click', (event) => {
    event.preventDefault();

    const reloadButton = event.target;
    const mpCompanyId = reloadButton.dataset.id;
    const year = (document.getElementById('financial_setting_closed_on_1i') as HTMLInputElement).value;
    const rawMonth = (document.getElementById('financial_setting_closed_on_2i') as HTMLInputElement).value;
    const month = ('0' + rawMonth).slice(-2);
    const yearMonth = year + month
    const balanceType = 'deposit';

    reloadBalanceChart(mpCompanyId, yearMonth, balanceType, '.js-account-balance-rows');

    return false;
  });

  $('.js-search-bank-name').on('input', () => {
    const searchInput: any = $('.js-search-bank-name').val() ? $('.js-search-bank-name').val() : '';
    const searchTarget: any = document.getElementsByClassName('d-InstitutionsIndex-select');

    $.each(searchTarget, function (idx: number, elem: any) {
      if (elem.parentNode.tagName !== 'A') return;

      if (elem.innerText.toLowerCase().indexOf(searchInput.toLowerCase()) == -1) {
        elem.parentNode.style.display = 'none';
      } else {
        elem.parentNode.style.display = '';
      }
    })
  });
});
